<template>
  <div class="collectionManagement">
    <v-list
      ref="list"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam="searchParam"
    >
      <template #searchSlot>
        <v-input
          label="应用名称:"
          clearable
          @change="$refs.list.search()"
          v-model="searchParam.name"
        />
        <!-- <v-select
          label="同步方式:"
          :options="typeOptions"
          clearable
          @change="$refs.list.search()"
          v-model="searchParam.type"
        /> -->
      </template>
      <template #operateSlot="{ row }">
        <v-button
          :text="row.status == 0 ? '开启任务' : '关闭任务'"
          type="text"
          @click="handelControlStatus(row)"
        />
      </template>
    </v-list>
  </div>
</template>

<script>
import { getSourceList, getTastStatus } from "./api.js";

export default {
  name: "collectionManagement",
  data() {
    return {
      // typeOptions: [
      //   {
      //     value:0,
      //     label:"全量同步",
      //   },
      //   {
      //     value:1,
      //     label:"增量同步",
      //   }
      // ],
      searchParam: {
        name: "",
      },
      headers: [
        {
          prop: "name",
          label: "应用名称",
        },
        {
          label: "数据库地址",
          formatter: (row, prop) => {
            const res = JSON.parse(row.config);
            return res.url;
          },
        },
        {
          label: "用户名",
          formatter: (row, prop) => {
            const res = JSON.parse(row.config);
            return res.username;
          },
        },
        {
          label: "查询语句",
          formatter: (row, prop) => {
            const res = JSON.parse(row.config);
            return res.sql;
          },
        },
        {
          prop: "type",
          label: "同步方式",
          formatter: (row, prop) => {
            const obj = {
              0: "全量同步",
              1: "增量同步",
            };
            return obj[row[prop]];
          },
        },
      ],
      tableUrl: getSourceList,
    };
  },
  mounted() {},
  methods: {
    //控制
    handelControlStatus(row) {
      const text = row.status == 0 ? "开启任务" : "开闭任务";
      const status = row.status == 0 ? 1 : 0;
      this.$confirm(`是否确定${text}?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then((res) => {
        this.$axios
          .post(`${getTastStatus}?id=${row.id}&operate=${row.status}`)
          .then((res) => {
            if (res.code == 200) {
              this.$refs.list.getList();
              this.$message(res.msg);
            }
          });
      });
    },
  },
};
</script>
<style lang="less" scoped>
.collectionManagement {
  box-sizing: border-box;
  height: 100%;
}
</style>
